import { FormProps } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'universal-cookie';
import {
  successLogin,
} from '../../../../store/AuthSlice';
import storage from '../../../../store/storage';
import AppService, { authApiUrl } from '../../../../store/AppService';
import AppConfig from '../../../../AppConfig';
import headers from '../../../../resources/scripts/headers';
import pushDataLayer from '../../../../resources/scripts/pushDataLayer';

const RESPONSE_TYPE = 'token';

const provider = {
  google: 'Google',
  facebook: 'Facebook',
  okta: 'oktaolx',
};

class LoginService {
  routes = {
    verifyToken: '/verify-token',
    getToken: '/token/new',
    login: '/login',
    loginSocial: '/login/social',
    logout: '/logout',
    register: '/register',
    resendConfirmationCode: '/resend-confirmation-code',
  };

  msg = {
    success: 'Bienvenido a Autofact<br><span>Tu cuenta fue verificada</span>',
    error: '<b>Oops!</b> ha ocurrido un error, intenta nuevamente.',
  };

  async login(dataForm: FormProps, hasSocial = false) {
    AppService.setError(false);
    AppService.setLoadingApp(true);
    try {
      let url = '';
      if (hasSocial) {
        url = `${authApiUrl}${this.routes.loginSocial}`;
      } else {
        url = `${authApiUrl}${this.routes.login}`;
      }
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(dataForm),
      });
      const responseData = await response.json();
      if (responseData.status === 'OK') {
        const { user, redirectTo } = responseData.data;
        try {
          const cookies = new Cookies();
          const decodeToken = atob(cookies.get('af_tk'));
          const userLogin = JSON.parse(decodeToken);
          const socialEvent = (userLogin.updatedAt === userLogin.createdAt ? 'sign_up' : 'login');
          const eventName = hasSocial ? socialEvent : 'login';
          const method = userLogin.red_social;
          console.log(JSON.stringify({ decodeToken, eventName, method }));
          pushDataLayer(eventName, { method });
        } catch (error) {
          console.error(error);
        }
        storage.dispatch(successLogin({
          loggedIn: true, verified: user.confirmed, redirectTo,
        }));
        return { success: true, data: user };
      }

      storage.dispatch(successLogin({
        loggedIn: false, verified: false, error: true, errorMessage: responseData.message,
      }));
      AppService.setError(true);
      return { success: false, error: responseData.message };
    } catch (err) {
      AppService.setError(true);
      return { success: false, error: this.msg.error };
    }
  }

  async verifyAccessToken() {
    try {
      AppService.setLoadingApp(true);
      const response = await fetch(`${authApiUrl}${this.routes.verifyToken}`, {
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
        },
        credentials: 'include',
      });

      const responseData = await response.json();
      if (responseData.status === 'OK') {
        if (responseData.data != null) {
          const { user, redirectTo } = responseData.data;
          storage.dispatch(successLogin({
            loggedIn: true, verified: user.confirmed, error: false, errorMessage: '', redirectTo,
          }));
          return { success: true, data: user };
        }
      }
      if (responseData.status === 'FAIL') {
        storage.dispatch(successLogin({
          loggedIn: false, verified: false, error: true, errorMessage: responseData.message,
        }));
      }

      return { success: false };
    } catch (err) {
      storage.dispatch(successLogin({ loggedIn: false, verified: false }));
      return { success: false };
    }
  }

  async logout(dataForm: FormProps) {
    AppService.setError(false);
    AppService.setLoadingApp(true);
    try {
      const response = await fetch(`${authApiUrl}${this.routes.logout}`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(dataForm),
      });
      storage.dispatch(successLogin({ loggedIn: false, verified: false }));
      const responseData = await response.json();
      if (responseData.status === 'OK') {
        const { redirectTo } = responseData.data;
        return { redirectTo };
      }
    } catch (err) {
      console.log(err);
      storage.dispatch(successLogin({ error: true }));
    }
    return { redirectTo: '/' };
  }

  async resendConfirmationCode(dataForm: FormProps) {
    AppService.setError(false);
    AppService.setLoadingApp(true);
    try {
      const url = `${authApiUrl}${this.routes.resendConfirmationCode}`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(dataForm),
      });
      const responseData = await response.json();
      if (responseData.status === 'OK') {
        return { success: true, data: [] };
      }
      return { success: false, error: responseData.message };
    } catch (err) {
      AppService.setError(true);
      return { success: false, error: this.msg.error };
    }
  }

  socialLogin(type: string) {
    AppService.setError(false);
    AppService.setLoadingApp(true);
    const identityId = uuidv4();
    sessionStorage.setItem('statetoken', identityId);
    const socialLogins = {
      google: this.googleLogin,
      facebook: this.facebookLogin,
      okta: this.oktaLogin,
    };
    socialLogins[type as keyof typeof socialLogins](identityId);
  }

  facebookLogin(state: string) {
    const host = headers.HeaderOption(['Host']).Host;
    const redirectUri = `https://${host}/oauth2/`;
    const url = `${AppConfig.Oauth2Serverurl}?identity_provider=${provider.facebook}&redirect_uri=${redirectUri}&response_type=${RESPONSE_TYPE}&client_id=${AppConfig.Oauth2ClientId}&state=${state}&scope=${AppConfig.Oauth2FacebookScopes}`;
    window.location.replace(url);
  }

  googleLogin(state: string) {
    const host = headers.HeaderOption(['Host']).Host;
    const redirectUri = `https://${host}/oauth2/`;
    const url = `${AppConfig.Oauth2Serverurl}?identity_provider=${provider.google}&redirect_uri=${redirectUri}&response_type=${RESPONSE_TYPE}&client_id=${AppConfig.Oauth2ClientId}&state=${state}&scope=${AppConfig.Oauth2GoogleScopes}`;
    window.location.replace(url);
  }

  oktaLogin(state: string) {
    const url = `${AppConfig.Oauth2Serverurl}?identity_provider=${provider.okta}&redirect_uri=${AppConfig.Oauth2RedirectUri}&response_type=${RESPONSE_TYPE}&client_id=${AppConfig.Oauth2ClientId}&state=${state}&scope=${AppConfig.Oauth2OktaScopes}`;
    window.location.replace(url);
  }
}

export default new LoginService();

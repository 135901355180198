import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormProps } from 'reactstrap/es/Form';
import { useParams } from 'react-router-dom';
import SignUpComponent from './components/SignUp/SignUpComponent';
import MailVerificationComponent from '../MailVerification/components/MailVerification/MailVerificationComponent';
import { StorageInterface } from '../../../store/StorageInterface';
import SignUpService from './store/SignUpService';
import LoadingComponent from '../../reusables/Loading/LoadingComponent';
import { IMailVerificationComponentProps } from '../MailVerification/components/MailVerification/interfaces/MailVerificationComponentInterfaces';
import ConfirmSignUpComponent from './components/ConfirmSignUp/ConfirmSignUpComponent';
import AppService from '../../../store/AppService';
import MessageComponent from '../../reusables/Message/MessageComponent';

function SignUpContainer({ type }: IMailVerificationComponentProps): JSX.Element {
  const [loadingMsg, setLoadingMsg] = useState('Cargando.');
  const [showErrorBag, setShowErrorBag] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [emailSended, setEmailSended] = useState('');
  const appSlice = useSelector(
    (state: StorageInterface) => state.appSlice,
  );
  const authSlice = useSelector(
    (state: StorageInterface) => state.authSlice,
  );
  const headerSlice = useSelector(
    (state: StorageInterface) => state.headerSlice,
  );

  type TokenParams = {
    token: string;
    email: string;
  };
  const { token, email } = useParams<TokenParams>();

  useEffect(() => {
    if (!headerSlice?.isRetailHost) {
      window.location.replace('/');
    }
  }, [headerSlice?.isRetailHost]);

  useEffect(() => {
    AppService.getToken();
  }, []);

  useEffect(() => {
    if (type === 'confirm') {
      AppService.setLoadingApp(true);
      setLoadingMsg('Verificando enlace.');
      if (!token || !email) {
        AppService.setLoadingApp(false);
      } else {
        const data: FormProps = {
          email,
          code: token,
        };
        ConfirmSignUp(data);
      }
    }
  }, [type, token, email]);

  const SignUpComponentProps = {
    showErrorBag: authSlice?.error,
    errorMessage,
    onSubmit: async (data: FormProps) => {
      SignUp(data);
    },
    socialRegister: async (registerType: string) => {
      SignUpService.socialRegister(registerType);
    },
    cancelAction: () => {
      console.log('cancel');
    },
  };

  const ConfirmSignUpComponentProps = {
    onSubmit: async (data: FormProps) => {
      ConfirmSignUp(data);
    },
    cancelAction: () => {
      console.log('cancel action');
    },
    showErrorBag,
    errorMessage,
    code: token,
    email,
  };

  async function SignUp(data: FormProps) {
    setLoadingMsg('Creando cuenta.');
    const result = await SignUpService.signUp(data);
    setShowErrorBag(!result.success);
    setErrorMessage(result.error);
    setSuccessMsg(result.message);
    setEmailSended(data.email);
    AppService.setLoadingApp(false);
  }

  async function ConfirmSignUp(data: FormProps) {
    setLoadingMsg('Confirmando cuenta.');
    const result = await SignUpService.confirmSignUp(data);
    setShowErrorBag(!result.success);
    setErrorMessage(result.error);
    setEmailSended(result.data?.email);
    AppService.setLoadingApp(false);
  }
  return (
    <div>
      {appSlice?.loading ? (
        <LoadingComponent text={loadingMsg} fullscreen />
      ) : (
        <>
          {type === 'confirm' ? (
            <>
              {!authSlice?.verified && (
                <ConfirmSignUpComponent {...ConfirmSignUpComponentProps} />
              )}
              {authSlice?.verified && (
                <MessageComponent type="welcome" text="¡Bienvenido(a) a Autofact!" subtext={`<div class="text-break"><b>${emailSended}</b></div><br />${SignUpService.msg.success}`} button="Ir al inicio" />
              )}
            </>
          ) : (
            <>
              {authSlice?.registered ? (
                <MailVerificationComponent type="account" message={successMsg} email={emailSended} />
              ) : (
                <div className="container-af d-flex justify-content-center">
                  {appSlice?.loading && (<LoadingComponent />)}
                  <SignUpComponent {...SignUpComponentProps} />
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default SignUpContainer;

import configs from './config.json';

// eslint-disable-next-line
const env: Record<string, string> = configs;

const AppConfig = {
  authApiUrlRetail: env.AUTH_API_URL_RETAIL,
  authApiUrlBusiness: env.AUTH_API_URL_BUSINESS,
  termsUrl: env.TERMS_URL,
  privacyUrl: env.PRIVACY_URL,
  autofactUrl: env.AUTOFACT_URL,
  homeAutofactUrlCl: env.HOME_AUTOFACT_URL_CL,
  allowHost: [env.BUSINESS_HOSTNAME].concat(env.RETAIL_HOSTNAME?.split(',')),
  allowHostRetail: env.RETAIL_HOSTNAME?.split(','),
  allowHostBusiness: env.BUSINESS_HOSTNAME,
  environment: env.ENVIRONMENT,
  defaultHost: env.DEFAULT_HOST,
  csrfTokenExpiration: env.CSRF_TOKEN_EXPIRATION,

  Oauth2Serverurl: env.OAUTH2_SERVER_URL,
  Oauth2RedirectUri: env.OAUTH2_REDIRECT_URI,
  Oauth2ClientId: env.OAUTH2_CLIENT_ID,
  Oauth2GoogleScopes: env.OAUTH2_GOOGLE_SCOPES,
  Oauth2FacebookScopes: env.OAUTH2_FACEBOOK_SCOPES,
  Oauth2OktaScopes: env.OAUTH2_OKTA_SCOPES,
  Oauth2OktaActive: env.OAUTH2_OKTA_ACTIVE,

  SocialFacebookUrl: env.SOCIAL_FACEBOOK_URL,
  SocialInstagramUrl: env.SOCIAL_INSTAGRAM_URL,
  SocialTwitterUrl: env.SOCIAL_TWITTER_URL,
  SocialYoutubeUrl: env.SOCIAL_YOUTUBE_URL,

  GTM_CL: env.REACT_APP_GMT_CL || 'GTM-NM62MBM',
};

export default AppConfig;

import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AppConfig from './AppConfig';

/*
import worker from './mocks/worker';

// Start the mocking conditionally.
if (process.env.NODE_ENV === 'development') {
  worker.start();
}
*/
TagManager.initialize({ gtmId: AppConfig.GTM_CL });
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
